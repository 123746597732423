import "../Footer/Footer";

const loader = document.querySelector('.loader');
const body = document.querySelector('body');
body.classList.add('loading');
window.addEventListener('load', () => {
    setTimeout(() => {
        loader.classList.add('ready');
        body.classList.remove('loading');
    }, 800);
    setTimeout(() => {
        loader.remove();
    }, 1200);
    setTimeout(() => {
        if (animItems.length > 0) {
            animOnScroll();
            window.addEventListener('scroll', animOnScroll);
        }
    }, 1200);
});


$(document).ready(function() {
    var cookieName = "cookies-notification-hide";
    var cookieValue = encodeURIComponent("1");
    var cookieFullValue = cookieValue + "; path=/; max-age=604800";
    if (document.cookie.indexOf(cookieName) === -1) {
        $(".popup-cookie").show();
    }

    $(".popup-cookie__close").click(function(e) {
        document.cookie = encodeURIComponent(cookieName) + "=" + cookieFullValue;
        $(".popup-cookie").hide();
    });
});

const marqueeRows = document.querySelectorAll('.changes-marquee__row');
function animateMarquee() {
    for (let index = 0; index < marqueeRows.length; index++) {
        const el = marqueeRows[index];
        const innerEl = el.children[0];
        const innerWidth = innerEl.offsetWidth;
        const dataSpeed = el.getAttribute('marque-data-speed');
        const duration = dataSpeed * 1000;
        const cloneEl = innerEl.cloneNode(true);
        el.appendChild(cloneEl);

        let start = performance.now();
        let progress;
        let translateX;

        requestAnimationFrame(function step(now) {
            progress = (now - start) / duration;

            if (progress > 1) {
                progress %= 1;
                start = now;
            }

            translateX = innerWidth * progress;

            innerEl.style.transform = `translateX(-${translateX}px)`;
            cloneEl.style.transform = `translateX(-${translateX}px)`;
            requestAnimationFrame(step);
      });
    }
};
if (marqueeRows.length > 0) {
	animateMarquee();
};

const animItems = document.querySelectorAll('.anim-item');

function animOnScroll(params) {
	for (let index = 0; index < animItems.length; index++) {
        const animItem = animItems[index];
        const animItemRect = animItem.getBoundingClientRect();
		const animItemHeight = animItem.offsetHeight;
		const animItemOffsetTop = offset(animItem).top;
		const animItemOffsetBottom = animItemOffsetTop + animItemHeight;
		const animStart = 3;

		let animItemPointY = window.innerHeight - animItemHeight / animStart;

		if (animItemHeight > window.innerHeight) {
			animItemPointY = window.innerHeight - window.innerHeight / animStart;
		}

		if(pageYOffset > animItemOffsetTop - animItemPointY && pageYOffset < animItemOffsetTop + animItemHeight) {
			animItem.classList.add('active');
		} else {
			if (!animItem.classList.contains('anim-item--no-hide')) {
				animItem.classList.remove('active');
			}
		}
	}
};

function offset(el) {
	const rect = el.getBoundingClientRect(),
	scrollTop = window.pageYOffset || document.documentElement.scrollTop,
	scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	return {
		top: rect.top + scrollTop,
		left: rect.left + scrollLeft
	}
};

$(".main-nav__toggler").click(function (){
    $(this).toggleClass("active");
    $(".main-nav__menu").fadeToggle();
});

$('a[href^="#"]').click(function () {
	let elementClick = $(this).attr("href").replace(/.*#/, '');
	if (elementClick && $('a[name='+elementClick+']').length)
	{
		let destination = $('a[name='+elementClick+']').offset().top;
		console.log(destination);
		$('html, body').animate( { scrollTop: destination }, 1100 );
	}
});

function setMainScreenHeight() {
    $(".main-screen").css("min-height", $(window).height() - $(".header").outerHeight());
};
setMainScreenHeight();

$(".main-screen__text-btn").click(function (){
    $(".main-screen__text-popup").fadeToggle();
});

$(".main-screen__text-popup-close").click(function (){
    $(".main-screen__text-popup").fadeOut();
});

$(document).click(function(e) {
    if ($(".main-nav__menu").is(":visible") && !window.matchMedia("(min-width: 768px)").matches) {
        var callbackPopup = $(".main-nav__menu, .main-nav__toggler")
        if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
            $(".main-nav__menu").fadeOut();
            $(".main-nav__toggler").removeClass("active");
        };
    }

    if ($(".main-screen__text-popup").is(":visible") && !window.matchMedia("(min-width: 768px)").matches) {
        var callbackPopup = $(".main-screen__text-popup, .main-screen__text-btn")
        if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
            $(".main-screen__text-popup").fadeOut();
        };
    }
});

$(window).resize(function() {
	if (window.matchMedia("(min-width: 768px)").matches) {
        if($(".main-nav__toggler").hasClass("active")) {
            $(".main-nav__toggler").removeClass("active");
        }
        $(".main-nav__menu").removeAttr("style");
        $(".main-screen__text-popup").removeAttr("style");
    }

    setMainScreenHeight();
});

// Form format tel
$.fn.formatPnoneNumber = function(){
	return this.each(function(){
		$(this).bind('keyup', function(){
			var num = this.value.replace(/^(8|7|\+7)/ , '' ).replace( /\D/g, '' ).split( /(?=.)/ ), i = num.length;
			if ( 0 <= i ) num.unshift( '+7' );
			if ( 1 <= i ) num.splice( 1, 0, '(' );
			if ( 4 <= i ) num.splice( 5, 0, ')' );
			if ( 7 <= i ) num.splice( 9, 0, '-' );
			if ( 9 <= i ) num.splice( 12, 0, '-' );
			if ( 11 <= i ) num.splice( 15, num.length - 15 );
			this.value = num.join( '' );
		});
	});
};

$('.request__form .jsPhoneInput').formatPnoneNumber()

// new ReqFormValidator('.popup--request');



